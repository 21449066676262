import {
  MOBILE_SECOND_DEPTH_MENU_DATA_OBJECT,
  NavBarActiveKey,
  SecondDepthMenuDataType,
} from 'app.layout/constant/pageLayout.type'

type GetSecondDepthMenuDataFn = (activityKey: NavBarActiveKey) => {
  activeType: NavBarActiveKey
  secondDepthMenuDataList: SecondDepthMenuDataType[] | undefined
}

const getSecondDepthMenuData: GetSecondDepthMenuDataFn = (
  activityKey: NavBarActiveKey,
) => {
  const PARSE_ACTIVITY_KEY_TO_MENU_TYPE = {
    [NavBarActiveKey.RECRUIT]: 'recruit',
    [NavBarActiveKey.INTERN]: 'recruit',
    [NavBarActiveKey.CALENDAR_RECRUIT]: 'recruit',
    [NavBarActiveKey.CALENDAR_ACTIVITY]: 'activity',
    [NavBarActiveKey.ACTIVITY]: 'activity',
    [NavBarActiveKey.EDUCATION]: 'activity',
    [NavBarActiveKey.CONTEST]: 'activity',
    [NavBarActiveKey.CLUB]: 'activity',
    [NavBarActiveKey.RECRUIT_HOME]: 'recruit',
  }

  const PARSE_ACTIVITY_KEY = {
    [NavBarActiveKey.RECRUIT]: NavBarActiveKey.RECRUIT_HOME,
    [NavBarActiveKey.INTERN]: NavBarActiveKey.RECRUIT_HOME,
    [NavBarActiveKey.CALENDAR_RECRUIT]: NavBarActiveKey.RECRUIT_HOME,
    [NavBarActiveKey.RECRUIT_HOME]: NavBarActiveKey.RECRUIT_HOME,
    [NavBarActiveKey.CALENDAR_ACTIVITY]: NavBarActiveKey.ACTIVITY,
    [NavBarActiveKey.ACTIVITY]: NavBarActiveKey.ACTIVITY,
    [NavBarActiveKey.EDUCATION]: NavBarActiveKey.ACTIVITY,
    [NavBarActiveKey.CONTEST]: NavBarActiveKey.ACTIVITY,
    [NavBarActiveKey.CLUB]: NavBarActiveKey.ACTIVITY,
  }

  return {
    activeType: PARSE_ACTIVITY_KEY[activityKey],
    secondDepthMenuDataList:
      MOBILE_SECOND_DEPTH_MENU_DATA_OBJECT[
        PARSE_ACTIVITY_KEY_TO_MENU_TYPE[activityKey]
      ],
  }
}

export default getSecondDepthMenuData
