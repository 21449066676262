import { FC, useRef, useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { ExpandMoreBold } from 'svgIcons'
import {
  MOBILE_MENU_ITEMS,
  NavBarActiveKey,
  NavBarBadge,
  TNavBar,
} from 'app.layout/constant/pageLayout.type'
import NewBadge from 'app.layout/desktop/NavBar/components/NewBadge'
import getSecondDepthMenuData from 'app.layout/module/getSecondDepthMenuData'
import { useNavTabScrollIntoView } from 'hooks/useNavTabScrollIntoView'

const NavBarMobile: FC<TNavBar> = ({ activeKey, isSticky = false }) => {
  const [expandMenuVisible, setExpandMenuVisible] = useState(false)
  const ref = useRef<HTMLAnchorElement[] | null[]>([])
  const { activeType, secondDepthMenuDataList } =
    getSecondDepthMenuData(activeKey)

  useNavTabScrollIntoView({
    targetTab: activeKey,
    navItems: MOBILE_MENU_ITEMS,
    parentRef: ref,
  })

  return (
    <StyledWrapper
      isSticky={isSticky}
      isHome={activeKey === NavBarActiveKey.ROOT}
    >
      <div className="menu-list">
        {MOBILE_MENU_ITEMS.map((menuItem, index) => (
          <Link key={menuItem.label} href={menuItem.href}>
            <a
              className="menu-item"
              data-active={menuItem.active === activeType}
              data-badge={menuItem.badge === NavBarBadge.NEW}
              ref={(el) => (ref.current[index] = el)}
            >
              {menuItem.label}
              <div className="active-line" />
              {menuItem.badge === NavBarBadge.NEW && <NewBadge />}
            </a>
          </Link>
        ))}
        <div className="gradient-box" />
        <div
          className="expand-more-btn"
          onClick={() => setExpandMenuVisible((prev) => !prev)}
        >
          <ExpandMoreBold />
        </div>
      </div>
      {expandMenuVisible && (
        <div className="expand-wrapper">
          <div className="expand-menu-list">
            {MOBILE_MENU_ITEMS.map((menuItem) => (
              <Link key={menuItem.label} href={menuItem.href}>
                <a
                  className="expand-menu-item"
                  data-active={menuItem.active === activeKey}
                >
                  {menuItem.label}
                </a>
              </Link>
            ))}
            <Link href="/nav">
              <a className="expand-menu-item">더보기 +</a>
            </Link>
          </div>
        </div>
      )}
      {secondDepthMenuDataList && (
        <div className="second-depth-nav">
          {secondDepthMenuDataList.map((menuItem) => (
            <Link key={menuItem.label} href={menuItem.href}>
              <a
                className="second-depth-item"
                data-active={menuItem.active === activeKey}
              >
                {menuItem.active === 8 && !expandMenuVisible && <NewBadge />}
                {menuItem.label}
              </a>
            </Link>
          ))}
        </div>
      )}
    </StyledWrapper>
  )
}

export default NavBarMobile

type TStyleProps = {
  isSticky: boolean
  isHome: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  ${({ theme, isSticky, isHome }) => css`
    position: ${isSticky ? 'sticky' : 'relative'};
    top: ${isHome ? '0px' : '-51px'};
    z-index: 10;
    padding: 0px 20px 0px 20px;
    background-color: ${theme.background.bg_8};

    .menu-list {
      display: flex;
      gap: 18px;
      border-bottom: 1px solid ${theme.line.line_3};
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -ms-overflow-style: none;

      ::-webkit-scrollbar {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }

      .menu-item {
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: -0.64px;
        padding: 0px;

        &:nth-last-of-type(1) {
          &::after {
            content: '';
            width: 30px; // active 상태가 가상선택자에 포함되지 않도록 width를 줌
            height: 100%;
            display: block;
          }

          &:active,
          &:hover {
            &::after {
              position: relative;
              z-index: 1;
              background-color: #ffffff;
            }
          }
        }

        .active-line {
          display: none;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: ${theme.color.primary_2};
        }

        &[data-active='true'] {
          background-color: #fff;
          color: ${theme.color.primary_2};

          .active-line {
            display: block;
          }
        }

        &[data-badge='true'] {
          margin-right: 10px;
        }
      }

      > .gradient-box {
        position: absolute;
        right: 0px;
        width: 50px;
        height: 50px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          #ffffff 22%
        );
        z-index: 2;
      }

      .expand-more-btn {
        position: absolute;
        top: 10px;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${theme.background.bg_8};
        border: 1px solid ${theme.line.line_3};
        z-index: 3;

        svg {
          width: 10px;
        }
      }
    }

    .expand-wrapper {
      position: absolute;
      top: 51px;
      z-index: 30;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: rgba(10, 10, 10, 0.86);

      .expand-menu-list {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 20px;
        row-gap: 8px;
        column-gap: 5px;
        background-color: ${theme.background.bg_8};

        .expand-menu-item {
          font-size: 13px;
          font-weight: normal;
          line-height: 1.85;
          letter-spacing: -0.52px;
          color: #333;
          padding: 7px 15px;
          background-color: #fff;
          border: 1px solid #dddddd;
          border-radius: 20px;

          &[data-active='true'] {
            color: ${theme.background.bg_8};
            background-color: ${theme.color.primary_2};
            border: none;
          }
        }
      }
    }

    .second-depth-nav {
      position: sticky;
      top: 0px;
      height: 44px;
      display: flex;
      align-items: center;
      gap: 24px;
      border-bottom: 1px solid ${theme.line.line_3};
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .second-depth-item {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -0.64px;
        flex-shrink: 0;

        &[data-active='true'] {
          color: ${theme.color.primary_2};
          font-weight: bold;
        }

        .new-badge {
          top: -3px;
          right: -9px;
        }
      }
    }
  `}
`
